import React from "react";

const Illustration = ({ width, height, style }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 442 345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clip-path="url(#clip0-about)">
        <path
          d="M127.919 333.867L111.11 343.574C108.677 344.977 104.745 344.961 102.322 343.54L29.2055 300.579C26.7827 299.157 26.7905 296.864 29.2237 295.461L45.3089 286.174C47.742 284.768 51.6849 284.768 54.118 286.174L127.919 328.783C130.35 330.191 130.35 332.464 127.919 333.867Z"
          fill="#F1EFFD"
        />
        <path
          d="M38.9744 219.572C37.3714 220.498 36.0692 222.752 36.0692 224.604C36.0692 226.456 37.3714 227.209 38.9744 226.28C40.5801 225.354 41.8797 223.1 41.8797 221.248C41.8797 219.396 40.5801 218.649 38.9744 219.572Z"
          fill="url(#paint0_linear-about)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.18354 206.43L36.92 226.485L41.0289 219.369L6.18586 199.253C5.57643 198.896 4.86659 198.69 4.10885 198.69C1.8396 198.69 0 200.53 0 202.799C0 204.373 0.884548 205.74 2.18354 206.43Z"
          fill="url(#gradient-illustration)"
        />
        <path
          d="M38.9822 201.684C37.3791 202.61 36.0769 204.864 36.0769 206.713C36.0769 208.566 37.3791 209.318 38.9822 208.392C40.5879 207.466 41.8874 205.212 41.8874 203.359C41.8874 201.507 40.5879 200.758 38.9822 201.684Z"
          fill="url(#paint2_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.1172 188.499L36.9278 208.597L41.0366 201.481L6.28385 181.417C5.65472 181.026 4.91206 180.8 4.1166 180.8C1.84735 180.8 0.00775146 182.639 0.00775146 184.909C0.00775146 186.452 0.85878 187.797 2.1172 188.499Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M38.9927 183.796C37.387 184.722 36.0874 186.976 36.0874 188.828C36.0874 190.68 37.387 191.432 38.9901 190.504C40.5957 189.578 41.8953 187.324 41.8953 185.471C41.8979 183.619 40.5957 182.87 38.9927 183.796Z"
          fill="url(#paint4_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.11528 170.606L36.9356 190.709L41.047 183.591L6.20133 163.475C5.59192 163.117 4.88214 162.912 4.12445 162.912C1.85519 162.912 0.0155945 164.751 0.0155945 167.021C0.0155945 168.56 0.862224 169.902 2.11528 170.606Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M39.0004 165.905C37.3947 166.831 36.0952 169.086 36.0952 170.938C36.0952 172.79 37.3947 173.542 39.0004 172.613C40.6035 171.687 41.9057 169.433 41.9057 167.584C41.9057 165.732 40.6035 164.982 39.0004 165.905Z"
          fill="url(#paint6_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.12375 152.718L36.9459 172.821L41.0548 165.703L6.20909 145.586C5.59969 145.229 4.88989 145.024 4.1322 145.024C1.86294 145.024 0.0233459 146.863 0.0233459 149.133C0.0233459 150.672 0.870301 152.014 2.12375 152.718Z"
          fill="url(#paint7_linear)"
        />
        <path
          d="M107.995 281.819H99.7772V322.08H107.995V281.819Z"
          fill="url(#paint8_linear)"
        />
        <path
          d="M92.5011 272.88H84.2834V313.141H92.5011V272.88Z"
          fill="url(#paint9_linear)"
        />
        <path
          d="M77.0047 263.944H68.787V304.203H77.0047V263.944Z"
          fill="url(#paint10_linear)"
        />
        <path
          d="M61.5057 255.008H53.288V295.266H61.5057V255.008Z"
          fill="url(#paint11_linear)"
        />
        <path
          d="M67.3343 229.258L127.867 194.309C126.97 192.755 125.732 191.399 124.36 190.61L59.6977 153.275C56.9584 151.695 52.5201 151.695 49.7861 153.275L30.0486 164.673L67.3343 229.258Z"
          fill="#5940E3"
        />
        <path
          d="M104.623 293.842L124.363 282.444C127.099 280.864 129.317 277.02 129.317 273.861V199.193C129.317 197.613 128.762 195.862 127.865 194.309L67.3343 229.257L104.623 293.842Z"
          fill="#2E18A4"
        />
        <path
          d="M106.099 212.627L106.075 290.633C106.075 293.795 103.855 295.077 101.118 293.497L33.5504 254.484C30.8138 252.904 28.5959 249.06 28.5959 245.9V167.881C28.5959 164.722 30.8138 163.44 33.5504 165.02L101.144 204.044C103.878 205.624 106.099 209.465 106.099 212.627Z"
          fill="#3E21DE"
        />
        <path
          d="M95.0198 281.013L39.6462 249.055C37.8201 248.002 36.3415 245.439 36.3415 243.333V179.378C36.3415 177.272 37.8201 176.419 39.6436 177.472L95.0457 209.468C96.8693 210.521 98.3452 213.084 98.3452 215.191L98.3219 279.104C98.3271 281.215 96.8459 282.068 95.0198 281.013Z"
          stroke="#F1EFFD"
          stroke-width="2.59397"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M44.1053 240.791L43.9082 190.592C43.9082 189.539 44.6475 189.111 45.5605 189.637L88.9603 214.892C89.8734 215.419 90.6127 216.7 90.6127 217.753L90.6049 267.641C90.6049 268.694 89.8656 269.122 88.9525 268.595L45.7577 243.649C44.8472 243.125 44.1053 241.844 44.1053 240.791Z"
          stroke="#F1EFFD"
          stroke-width="2.59397"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M100.981 190C102.586 189.074 105.188 189.074 106.791 190C108.397 190.926 108.397 192.428 106.791 193.354C105.188 194.28 102.586 194.28 100.981 193.354C99.3776 192.428 99.3776 190.926 100.981 190Z"
          fill="#C3BAF5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.7772 151.417C99.7772 151.417 99.7772 151.417 99.7772 151.417C99.7772 149.148 101.617 147.309 103.886 147.309C106.155 147.309 107.995 149.148 107.995 151.417C107.995 151.417 107.995 151.417 107.995 151.417V191.679H99.7772V151.417Z"
          fill="url(#paint12_linear)"
        />
        <path
          d="M85.487 181.062C87.09 180.136 89.6918 180.136 91.2975 181.062C92.9005 181.988 92.9005 183.49 91.2975 184.416C89.6918 185.342 87.09 185.342 85.487 184.416C83.8839 183.49 83.8813 181.99 85.487 181.062Z"
          fill="#C3BAF5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.2834 142.482C84.2834 142.481 84.2834 142.48 84.2834 142.479C84.2848 140.211 86.1239 138.373 88.3922 138.373C90.6606 138.373 92.4997 140.211 92.5011 142.479C92.5011 142.48 92.5011 142.481 92.5011 142.482V182.74H84.2834V142.482Z"
          fill="url(#paint13_linear)"
        />
        <path
          d="M69.9906 172.125C71.5937 171.199 74.1954 171.199 75.8011 172.125C77.4042 173.051 77.4042 174.553 75.8011 175.479C74.1954 176.405 71.5937 176.405 69.9906 175.479C68.3849 174.553 68.3849 173.051 69.9906 172.125Z"
          fill="#C3BAF5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M77.0043 133.543C77.0043 133.543 77.0043 133.543 77.0043 133.543C77.0043 131.273 75.1647 129.434 72.8954 129.434C70.6262 129.434 68.7866 131.273 68.7866 133.543C68.7866 133.562 68.7867 133.581 68.787 133.6V173.801H77.0047V133.543H77.0043Z"
          fill="url(#paint14_linear)"
        />
        <path
          d="M54.4916 163.189C56.0972 162.263 58.699 162.263 60.3021 163.189C61.9077 164.115 61.9077 165.617 60.3021 166.543C58.699 167.469 56.0972 167.469 54.4916 166.543C52.8885 165.617 52.8885 164.115 54.4916 163.189Z"
          fill="#C3BAF5"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M61.5057 124.606C61.5056 122.337 59.666 120.498 57.3968 120.498C55.1276 120.498 53.288 122.337 53.288 124.606C53.288 124.612 53.288 124.617 53.288 124.622V164.867H61.5057V124.606H61.5057Z"
          fill="url(#paint15_linear)"
        />
        <path
          d="M114.853 270.206C114.853 268.354 116.156 266.099 117.759 265.176C118.591 264.693 119.336 264.675 119.865 265.015L154.619 285.082L154.614 285.092C156.567 286.231 157.236 288.734 156.103 290.695C154.967 292.662 152.458 293.336 150.492 292.2C150.456 292.179 150.43 292.151 150.396 292.132L115.647 272.071L115.658 272.053C115.162 271.731 114.853 271.101 114.853 270.206Z"
          fill="url(#paint16_linear)"
        />
        <path
          d="M115.183 252.655C115.183 250.803 116.482 248.549 118.088 247.623C118.871 247.171 119.577 247.125 120.098 247.402L120.101 247.397L120.14 247.42C120.142 247.42 120.142 247.42 120.142 247.42L154.969 267.526L154.961 267.539C156.917 268.675 157.586 271.181 156.453 273.142C155.317 275.108 152.806 275.78 150.84 274.647C150.803 274.626 150.777 274.597 150.744 274.577L115.995 254.515L116 254.51C115.494 254.196 115.183 253.56 115.183 252.655Z"
          fill="url(#paint17_linear)"
        />
        <path
          d="M115.191 234.767C115.191 232.915 116.493 230.661 118.096 229.735C118.879 229.283 119.585 229.237 120.106 229.517L120.109 229.512L120.148 229.532C120.148 229.532 120.15 229.532 120.153 229.535L154.977 249.641L154.969 249.651C156.925 250.79 157.594 253.293 156.461 255.254C155.325 257.22 152.814 257.892 150.847 256.759C150.814 256.741 150.788 256.712 150.751 256.691L116.003 236.627L116.008 236.622C115.502 236.305 115.191 235.67 115.191 234.767Z"
          fill="url(#paint18_linear)"
        />
        <path
          d="M115.198 216.879C115.198 215.027 116.501 212.773 118.104 211.847C118.887 211.395 119.593 211.349 120.114 211.626L120.119 211.621L120.155 211.644H120.161L154.985 231.75L154.977 231.76C156.933 232.899 157.602 235.405 156.468 237.366C155.332 239.33 152.821 240.004 150.855 238.871C150.821 238.85 150.795 238.821 150.759 238.801L116.01 218.739L116.013 218.734C115.512 218.417 115.198 217.782 115.198 216.879Z"
          fill="url(#paint19_linear)"
        />
        <path
          opacity="0.5"
          d="M341.966 236.43L318.27 222.752C302.2 213.473 280.006 211.243 268.702 217.772C257.395 224.301 261.257 237.112 277.327 246.391L301.02 260.069C317.09 269.348 339.284 271.578 350.591 265.049C361.895 258.523 358.036 245.709 341.966 236.43Z"
          fill="#F1EFFD"
        />
        <path
          opacity="0.5"
          d="M439.263 204.784L380.191 170.678C376.546 168.572 370.629 168.572 366.982 170.678L307.91 204.784C304.263 206.89 304.263 210.304 307.91 212.41L366.982 246.516C370.629 248.622 376.544 248.622 380.191 246.516L439.263 212.41C442.913 210.304 442.913 206.89 439.263 204.784Z"
          fill="#F1EFFD"
        />
        <path
          d="M439.263 134.583L380.191 100.477C376.546 98.3711 370.629 98.3711 366.982 100.477L307.91 134.583C304.262 136.689 304.262 140.103 307.91 142.209L366.982 176.315C370.629 178.421 376.543 178.421 380.191 176.315L439.263 142.209C442.913 140.103 442.913 136.689 439.263 134.583Z"
          fill="#F1EFFD"
        />
        <path
          d="M373.586 101.492C375.604 101.492 377.537 101.94 378.894 102.724L437.966 136.832C439.027 137.444 439.406 138.059 439.406 138.399C439.406 138.739 439.027 139.353 437.966 139.965L378.894 174.071C377.54 174.854 375.602 175.303 373.586 175.303C371.571 175.303 369.636 174.854 368.279 174.071L309.207 139.965C308.146 139.353 307.767 138.739 307.767 138.399C307.767 138.059 308.146 137.444 309.207 136.832L368.279 102.726C369.636 101.94 371.571 101.492 373.586 101.492ZM373.586 98.8977C371.197 98.8977 368.806 99.4243 366.982 100.475L307.91 134.58C304.262 136.687 304.262 140.1 307.91 142.207L366.982 176.312C368.806 177.365 371.195 177.892 373.586 177.892C375.978 177.892 378.367 177.365 380.191 176.312L439.263 142.207C442.91 140.1 442.91 136.687 439.263 134.58L380.191 100.475C378.37 99.4243 375.978 98.8977 373.586 98.8977Z"
          fill="white"
        />
        <path
          d="M442 198.014C441.966 199.588 440.944 200.823 439.263 201.827L380.191 235.932C378.487 236.918 376.313 237.471 373.587 237.512V177.892C376.178 177.877 378.375 177.345 380.191 176.318L439.263 142.212C440.732 141.372 442 140.116 442 138.399V198.014Z"
          fill="#C3BAF5"
        />
        <path
          d="M305.171 198.014C305.204 199.588 306.229 200.823 307.907 201.827L366.98 235.932C368.687 236.918 370.86 237.471 373.587 237.512V177.892C370.995 177.877 368.798 177.345 366.98 176.318L307.907 142.212C306.442 141.372 305.171 140.116 305.171 138.399V198.014V198.014Z"
          fill="#7864E8"
        />
        <path
          d="M330.475 104.171C326.695 111.24 322.566 116.067 317.302 122.111C315.868 123.758 315.209 125.335 312.843 128.798C310.478 132.261 306.913 135.688 303.741 137.187C300.566 138.684 299.186 138.723 298.605 138.214C297.873 137.574 298.203 136.712 299.573 135.864C300.942 135.016 303.355 133.833 305.596 131.182C305.596 131.182 302.69 133.192 300.846 134.24C299.002 135.288 297.051 136.025 295.619 136.362C293.842 136.782 291.827 134.98 293.186 134.087C294.553 133.19 295.84 132.876 298.551 131.281C301.015 129.831 302.53 128.285 302.53 128.285C302.53 128.285 299.503 129.857 296.717 130.692C293.933 131.527 292.613 131.299 291.752 131.102C290.891 130.905 290.066 129.509 291.9 128.627C293.547 127.836 295.97 127.543 298.877 125.543C300.989 124.09 302.281 122.772 301.783 122.645C301.287 122.521 300.104 123.46 297.884 123.737C295.765 124.005 293.526 123.001 293.472 121.8C293.414 120.599 293.902 121.167 296.04 120.555C298.174 119.942 299.796 118.609 302.875 117.987C305.588 117.437 307.334 116.988 309.627 115.81C311.917 114.63 316.682 107.214 320.272 100.552C323.419 93.1856 326.607 81.0017 327.943 76.1847C330.202 68.0318 333.31 51.3707 334.96 45.7262C336.612 40.0817 339.304 38.7899 342.661 38.0092C346.604 37.0909 348.669 42.035 347.953 46.8105C346.692 55.1916 340.145 87.7201 330.475 104.171Z"
          fill="#F8DDDE"
        />
        <path
          d="M314.314 109.925L310.872 114.358C310.872 114.358 311.523 116.43 314.872 118.365C318.221 120.3 319.448 120.02 319.448 120.02L322.807 115.727L314.314 109.925Z"
          fill="#F5FBFB"
        />
        <path
          d="M343.006 37.1559C338.996 36.5256 335.681 40.2687 333.974 46.0948C332.27 51.9182 328.752 68.9295 327.738 73.4378C322.106 98.4385 312.623 111.766 312.623 111.766C312.623 111.766 316.197 116.752 321.79 117.447C321.79 117.447 331.808 105.515 338.866 89.383C344.108 77.3936 346.749 61.0516 347.548 56.3487C349.416 45.3166 350.217 38.2895 343.006 37.1559Z"
          fill="#6D89C5"
        />
        <path
          d="M302.818 211.842C302.818 211.842 303.085 214.096 303.583 215.938C304.081 217.78 304.512 220.83 304.177 222.327C303.842 223.823 299.583 225.948 297.212 226.262C294.846 226.576 291.186 229.097 289.845 230.804C287.926 233.245 282.803 235.058 279.5 234.679C276.201 234.301 272.479 233.522 271.68 232.189C270.883 230.853 271.991 229.359 278.917 224.423C285.843 219.486 290.483 216.496 292.66 211.917L302.818 211.842Z"
          fill="#1D0F68"
        />
        <path
          d="M327.927 258.684C326.169 261.205 323.201 262.372 320.195 262.806C318.61 263.034 316.999 263.013 315.396 262.992C313.705 262.969 311.108 261.978 311.261 259.872C311.313 259.202 311.741 258.629 312.151 258.095C314.861 254.583 317.78 251.223 320.262 247.537C323.058 243.392 325.831 239.473 326.843 233.226L337.237 233.346C337.276 234.22 337.515 235.444 337.673 236.591C337.831 237.75 338.14 238.884 338.399 240.023C338.913 242.277 339.266 244.643 338.103 246.77C337.232 248.36 335.507 249.249 334.106 250.404C331.442 252.603 329.904 255.846 327.927 258.684Z"
          fill="#1D0F68"
        />
        <path
          d="M382.178 122.723C385.941 146.242 375.488 155.023 361.908 160.027C352.163 163.62 338.186 169.147 338.186 169.147C338.186 169.147 338.378 174.312 338.487 185.897C338.583 196.099 337.237 233.345 337.237 233.345C337.237 233.345 331.611 236.259 326.843 233.226C326.843 233.226 321.365 204.692 320.2 191.826C318.625 174.374 315.946 162.953 316.838 159.197C317.624 155.887 336.695 146.51 342.752 141.488L302.786 154.88C302.786 154.88 304.109 161.713 303.943 168.019C303.578 181.868 302.812 211.841 302.812 211.841C302.812 211.841 297.331 215.802 292.657 211.917C292.657 211.917 282.6 152.577 283.819 144.72C284.976 137.265 320.195 122.028 326.397 116.783C329.935 113.789 333.883 110.594 333.883 110.594L382.178 122.723Z"
          fill="#C7D8EF"
        />
        <path
          d="M342.755 141.488C342.755 141.488 336.023 137.219 333.761 130.324C333.761 130.324 333.351 135.348 335.925 139.608C336.202 141.283 302.789 154.881 302.789 154.881L342.755 141.488Z"
          fill="#ADC9E9"
        />
        <path
          d="M368.453 32.8135C368.453 32.8135 367.877 44.0168 368.126 45.0103C368.375 46.0038 373.267 49.2852 374.692 50.4629C376.118 51.6405 366.949 60.6857 362.487 62.7298C358.025 64.7738 343.901 59.3835 343.655 52.4473C343.406 45.5084 346.07 39.6849 348.238 39.3736C350.404 39.0624 368.453 32.8135 368.453 32.8135Z"
          fill="#F8DDDE"
        />
        <path
          d="M350.049 45.633C348.573 44.3049 350.274 41.3634 351.291 39.3167C355.919 37.9186 368.45 32.811 368.45 32.811C368.45 32.811 368.326 35.3946 368.248 37.1923C368.248 39.296 366.923 41.4412 365.252 42.7148C363.997 43.672 362.339 44.8185 360.256 45.6253C358.414 46.3438 353.449 48.6939 350.049 45.633Z"
          fill="#F8CECF"
        />
        <path
          d="M369.721 46.6809C369.721 46.6809 364.91 54.5951 349.47 56.0607C347.32 51.6795 347.906 44.5824 351.299 39.8535C351.299 39.8535 346.749 36.1831 343.636 36.8964C343.636 36.8964 338.729 39.4385 336.635 45.8301C334.539 52.2216 332.446 59.8738 332.635 71.7594C332.825 83.645 332.257 113.154 332.257 113.154C332.257 113.154 330.314 121.849 344.702 128.443C353.96 132.684 362.648 133.774 369.605 132.152C375.348 130.814 381.488 128.627 382.725 125.932C381.589 116.443 378.647 99.8002 379.449 89.842C380.297 79.3105 382.53 71.6349 383.101 63.1682C383.674 54.6988 381.986 54.8701 377.742 51.8507C372.027 47.7885 369.721 46.6809 369.721 46.6809Z"
          fill="#F5FBFB"
        />
        <path
          d="M351.299 39.8538C351.299 39.8538 337.764 57.5084 346.773 77.7388C346.773 77.7388 344.718 120.952 344.358 131.208C338.794 129.172 335.87 125.375 335.87 125.375C335.87 125.375 330.825 121.922 330.908 113.027C330.908 113.027 331.717 97.2636 332.171 83.3936C332.628 69.5237 331.764 57.8508 335.154 47.9885C338.55 38.1236 344.404 36.3701 344.404 36.3701L351.299 39.8538Z"
          fill="#82A6D7"
        />
        <path
          d="M350.044 41.9131V37.0857C350.044 37.0857 345.499 40.7614 343.636 45.0103C341.777 49.2566 339.341 58.6857 338.895 61.5546C338.449 64.4236 342.915 70.6621 342.915 70.6621C342.915 70.6621 339.852 73.6892 340.376 75.7151C340.9 77.7384 346.179 88.8614 346.015 92.3217C345.994 91.7122 346.775 77.7384 346.775 77.7384C346.775 77.7384 344.179 66.7633 344.892 58.9866C346.013 46.7638 350.044 41.9131 350.044 41.9131Z"
          fill="#6D89C5"
        />
        <path
          d="M368.126 45.01C368.095 48.5975 362.749 58.1952 346.775 77.7408C345.634 97.4186 344.36 131.21 344.36 131.21C344.36 131.21 372.079 145.973 384.997 132.601C384.997 132.601 384.147 126.334 381.931 115.602C379.716 104.871 380.398 89.271 381.763 78.1584C383.127 67.0458 386.359 57.3366 381.931 53.5883C377.501 49.8374 368.126 45.01 368.126 45.01Z"
          fill="#82A6D7"
        />
        <path
          d="M343.099 16.1162C344.438 7.41863 350.7 0.829942 359.67 1.32798C369.21 1.85716 376.512 10.0204 375.983 19.561C375.675 25.1407 372.736 29.8798 368.456 32.8084C368.456 32.8084 367.633 35.9497 367.068 36.6112C363.895 40.3206 354.303 42.6111 351.548 42.2168C348.012 41.711 346.093 40.235 344.409 36.37C341.432 29.5426 342.365 20.4144 343.099 16.1162Z"
          fill="#F8DDDE"
        />
        <path
          d="M344.404 9.10454C348.111 3.47303 354.912 0.137181 362.321 1.678C369.623 3.19547 375.257 9.52736 375.934 16.9565C376.543 23.6386 373.371 29.4309 368.453 32.8109C368.642 32.222 373.161 25.4647 368.507 23.968C367.768 24.8292 367.574 26.1444 367.54 27.514C367.54 27.514 365.231 27.2961 365.75 24.5906C365.895 23.8357 366.028 23.1431 366.152 22.5206C366.564 20.4195 366.025 18.2976 364.827 16.5233C363.862 15.0914 363.039 13.1019 363.605 10.8762C363.605 10.8762 357.844 13.452 350.479 11.8931C347.374 11.2394 345.265 10.0695 344.404 9.10454Z"
          fill="#3B2D6D"
        />
        <path
          d="M363.605 10.8788C363.605 10.8788 360.663 13.4417 353.455 14.2095C346.246 14.9747 339.831 10.814 338.314 7.8672C336.023 3.42373 340.594 6.62729 344.741 4.41203C348.889 2.19678 364.391 -5.21161 370.951 6.20187C370.951 6.20187 374.694 6.79849 375.675 9.01115C376.658 11.2264 377.008 19.1665 374.832 24.0977L363.605 10.8788Z"
          fill="#3B2D6D"
        />
        <path
          d="M368.453 32.7487C368.453 32.7487 368.852 33.6721 370.539 33.7551C373.685 33.9108 377.369 28.0666 374.832 24.0952C373.247 21.6128 369.667 22.4843 368.508 23.9655L368.453 32.7487Z"
          fill="#F8DDDE"
        />
        <path
          d="M365.991 145.635C367.89 144.481 369.216 142.868 372.02 141.454C374.492 140.209 375.488 139.654 377.169 137.7C378.99 135.586 381.607 127.95 383.316 120.578C385.329 111.88 384.821 100.337 383.91 95.4239C382.061 85.4475 377.231 71.6813 375.815 65.9745C374.398 60.2678 376.074 57.7957 378.58 55.4326C381.527 52.6545 385.986 53.386 389.015 61.3054C392.043 69.2222 395.926 83.7329 397.163 95.2242C397.76 100.791 397.954 109.769 395.404 120.956C393.625 128.772 390.919 134.518 387.436 141.737C386.486 143.706 386.271 145.399 384.901 149.363C383.532 153.327 380.997 157.575 378.333 159.856C375.669 162.136 374.349 162.54 373.651 162.203C372.775 161.78 372.865 160.862 373.963 159.682C375.062 158.499 377.075 156.725 378.538 153.576C378.538 153.576 376.269 156.281 374.764 157.78C373.262 159.277 371.576 160.501 370.282 161.207C368.681 162.081 366.258 160.875 367.335 159.656C368.417 158.431 369.576 157.786 371.768 155.534C373.765 153.485 374.819 151.591 374.819 151.591C374.819 151.591 372.31 153.908 369.846 155.448C367.382 156.989 366.046 157.116 365.164 157.155C364.279 157.192 363.115 156.066 364.653 154.73C366.033 153.531 368.29 152.608 370.567 149.91C372.225 147.952 373.12 146.338 372.609 146.349C372.095 146.359 371.2 147.578 369.135 148.432C367.159 149.249 364.736 148.873 364.365 147.729C363.994 146.585 364.093 146.79 365.991 145.635Z"
          fill="#F8DDDE"
        />
        <path
          d="M380.237 130.795L378.211 135.402C378.211 135.402 378.704 137.61 382.878 139.49C387.052 141.371 388.414 140.658 388.414 140.658L390.748 135.467L380.237 130.795Z"
          fill="#F5FBFB"
        />
        <path
          d="M346.773 77.7387C346.773 77.7387 357.576 62.3461 361.517 57.1659C365.457 51.9858 368.025 43.6903 368.025 43.6903L368.116 40.8991C368.116 40.8991 369.823 40.7824 370.505 42.261C371.187 43.7395 374.842 48.7563 374.842 48.7563C374.842 48.7563 371.415 62.2839 367.659 66.4939C363.903 70.7039 358.445 72.0683 358.445 72.0683C358.445 72.0683 360.038 77.871 358.217 79.1213C356.396 80.3716 349.846 86.068 346.013 92.3195L346.773 77.7387Z"
          fill="#6D89C5"
        />
        <path
          d="M379.405 53.9567C376.595 55.5131 373.631 59.0253 375.675 67.3157C377.719 75.6086 381.223 85.9767 382.878 93.4577C384.538 100.936 384.766 110.321 383.21 117.488C381.654 124.655 379.2 132.352 379.2 132.352C379.2 132.352 384.925 137.669 390.294 137.42C390.294 137.42 395.889 126.168 397.544 112.181C399.199 98.1943 396.299 77.6967 390.364 63.1653C384.995 50.0061 379.405 53.9567 379.405 53.9567Z"
          fill="#6D89C5"
        />
        <path
          d="M286.348 132.518L313.204 117.014C313.684 116.739 314.27 116.739 314.747 117.014L361.851 144.214L334.231 160.164L286.348 132.518Z"
          fill="#F0F5FC"
        />
        <path
          d="M334.477 157.884L348.962 149.532L306.607 125.078L292.047 133.389L334.477 157.884Z"
          fill="#AECFED"
        />
        <path
          d="M341.595 134.645L341.328 134.801L334.228 138.899L321.603 131.607L321.341 131.46L328.708 127.208L341.595 134.645Z"
          fill="#AFB6B7"
        />
        <path
          d="M341.328 134.8L334.228 138.899L321.603 131.607L328.708 127.511L341.328 134.8Z"
          fill="#AECFED"
        />
        <path
          d="M361.851 144.217V144.847C361.851 145.773 361.358 146.629 360.559 147.091L335.528 161.542C335.131 161.773 334.682 161.887 334.231 161.887C333.787 161.887 333.338 161.775 332.939 161.542L287.648 135.395C286.844 134.933 286.351 134.074 286.351 133.151V132.521L334.231 160.164L361.851 144.217Z"
          fill="#CAE0F4"
        />
        <path
          d="M334.228 160.164V161.887C333.784 161.887 333.336 161.775 332.936 161.542L287.645 135.395C286.841 134.933 286.348 134.074 286.348 133.151V132.521L334.228 160.164Z"
          fill="#AECFED"
        />
        <path
          d="M333.499 160.546C332.643 160.875 331.668 160.816 330.856 160.341L285.762 134.305C284.968 133.846 284.424 133.063 284.271 132.16L279.334 102.721C279.308 102.553 279.293 102.387 279.293 102.218C279.293 101.398 279.62 100.612 280.201 100.036L327.061 127.097C327.647 127.437 328.049 128.018 328.163 128.684L333.499 160.546Z"
          fill="#F0F5FC"
        />
        <path
          d="M334.228 160.167L333.922 160.343C333.784 160.421 333.647 160.489 333.499 160.548L328.163 128.687C328.049 128.02 327.647 127.442 327.061 127.099L280.201 100.039C280.382 99.8572 280.595 99.699 280.826 99.5615L327.785 126.671C328.371 127.011 328.773 127.589 328.884 128.256L334.228 160.167Z"
          fill="#CAE0F4"
        />
        <path
          d="M308.104 133.369C307.772 131.397 305.738 128.78 303.557 127.522C301.378 126.264 299.879 126.84 300.211 128.814C300.54 130.785 302.574 133.402 304.755 134.66C306.934 135.918 308.434 135.34 308.104 133.369Z"
          fill="#AECFED"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear-about"
          x1="36.1585"
          y1="219.742"
          x2="43.1305"
          y2="221.855"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="gradient-illustration"
          x1="0.630964"
          y1="200.788"
          x2="40.9418"
          y2="224.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="36.1663"
          y1="201.853"
          x2="43.1382"
          y2="203.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="0.638715"
          y1="182.897"
          x2="40.9513"
          y2="206.17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="36.1767"
          y1="183.965"
          x2="43.1463"
          y2="186.075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="0.646598"
          y1="165.009"
          x2="40.9606"
          y2="188.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="36.1845"
          y1="166.076"
          x2="43.1564"
          y2="168.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="0.65435"
          y1="147.121"
          x2="40.9684"
          y2="170.397"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="103.886"
          y1="281.819"
          x2="103.886"
          y2="322.08"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3BAF5" />
          <stop offset="1" stop-color="#F1EFFD" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="88.3922"
          y1="272.88"
          x2="88.3922"
          y2="313.141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3BAF5" />
          <stop offset="1" stop-color="#F1EFFD" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="72.8958"
          y1="263.944"
          x2="72.8958"
          y2="304.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3BAF5" />
          <stop offset="1" stop-color="#F1EFFD" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="57.3968"
          y1="255.008"
          x2="57.3968"
          y2="295.266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3BAF5" />
          <stop offset="1" stop-color="#F1EFFD" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="103.886"
          y1="147.309"
          x2="103.886"
          y2="191.679"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="88.3922"
          y1="138.373"
          x2="88.3922"
          y2="182.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="72.8956"
          y1="129.434"
          x2="72.8956"
          y2="173.801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="57.3968"
          y1="120.498"
          x2="57.3968"
          y2="164.867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="155.873"
          y1="290.341"
          x2="115.937"
          y2="267.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="156.243"
          y1="273.13"
          x2="116.186"
          y2="249.764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="156.25"
          y1="255.242"
          x2="116.195"
          y2="231.876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="156.258"
          y1="237.354"
          x2="116.202"
          y2="213.987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1EFFD" />
          <stop offset="1" stop-color="#C3BAF5" />
        </linearGradient>
        <clipPath id="clip0-about">
          <rect width="442" height="344.62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Illustration;
