import React from "react";
import AboutSection from "../containers/AboutSection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="About"
      pathname="/about"
      description="VisualEyes is a derived product of Loceye. Our findings during the previous years taught us that designers nowadays are iterating faster than before."
    >
      <AboutSection />
    </MainLayout>
  );
};
