import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Button from "reusecore/src/elements/Button";
import SectionLayout from "../../components/SectionLayout";
import Illustration from "./Illustration";

const AboutSection = ({ row, col, textStyle, btnStyle }) => {
  return (
    <SectionLayout
      sectionId="service_section"
      title="About Us"
      description="We are on a mission to evolve the design process!"
    >
      <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          <Illustration width={300} style={{ margin: "0 auto 2rem auto" }} />
          <Text
            style={textStyle}
            content="Since 2017, we are creating state of the art technology. We use web-based eye tracking to provide insights to brands, agencies, and market researchers. As a consultancy service, we remain dedicated in our mission to make eye tracking more accessible and help companies evolve their Design Processes and Guidelines."
          />
          <Text
            style={textStyle}
            content={
              <React.Fragment>
                VisualEyes is a derived product of{" "}
                <a
                  href={"https://www.loceye.io"}
                  target="_blank"
                  rel="noreferrer"
                  rel="noopener noreferrer"
                >
                  Loceye
                </a>
                . Our findings during the previous years taught us that
                designers nowadays are iterating faster than before, leaving
                them with little time for actual user testing. That's the reason
                we created VisualEyes!
              </React.Fragment>
            }
          />
          <Text
            style={textStyle}
            content=" To learn how VisualEyes can improve your design process and increase
          conversion arrange to speak to one of our experts."
          />

          <Link to="/contact">
            <Button
              className="outlined"
              title="SPEAK TO AN EXPERT"
              {...btnStyle}
            />
          </Link>
        </Box>
      </Box>
    </SectionLayout>
  );
};

// AboutSection style props
AboutSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object
};

// AboutSection default style
AboutSection.defaultProps = {
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap"
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    color: "#525f7f"
  },
  btnStyle: {
    fontSize: ["16px", "18px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    width: "100%",
    mt: "2rem"
  },
  textStyle: {
    fontSize: "18px"
  }
};

export default AboutSection;
