import React from "react";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Container from "./Container";

const SectionLayout = ({
  sectionWrapperStyle,
  sectionHeaderStyle,
  sectionTitleStyle,
  sectionSubTitleStyle,
  children,
  sectionId,
  title,
  description,
  style,
  row,
  col,
  as
}) => {
  const hasTitleAndDescription = title || description;
  return (
    <Box {...sectionWrapperStyle} id={sectionId} style={style}>
      <Container>
        {hasTitleAndDescription && (
          <Box className="row" {...row} justifyContent="center">
            <Box className="col" {...col} flexDirection="column" flexBox={true}>
              {title && (
                <Heading
                  {...sectionTitleStyle}
                  content={title}
                  as={as || "h1"}
                />
              )}
              {description && (
                <Text {...sectionSubTitleStyle} content={description} />
              )}
            </Box>
          </Box>
        )}
        {children}
      </Container>
    </Box>
  );
};

SectionLayout.defaultProps = {
  sectionWrapperStyle: {
    as: "section",
    pt: ["60px", "80px", "80px", "80px"],
    pb: ["60px", "80px", "80px", "80px"]
  }, // section header default style
  row: {
    flexBox: true,
    flexWrap: "wrap"
  },
  // feature col default style
  col: {
    mb: ["50px", "60px", "60px", "60px"],
    width: [3 / 4, 1, 1, 3 / 4]
  },
  sectionSubTitleStyle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: ["20px", "22px"],
    fontWeight: "normal",
    color: "#525f7f",
    lineHeight: 1.8
  },
  sectionTitleStyle: {
    textAlign: "center",
    fontSize: ["28px", "32px"],
    fontWeight: "900",
    color: "#32325d",
    mb: "24px",
    mt: "40px"
  }
};

export default SectionLayout;
